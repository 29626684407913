import * as React from "react"

import ThemeConfig from "../components/theme/theme-config"
import WebWizardModalMobile from "../components/homepage/web-wizard/web-wizard-modal-mobile"
import WebWizardProvider from "../contexts/web-wizard-provider"

const WebWizardMobile = () => (
  <ThemeConfig>
    <WebWizardProvider>
      <WebWizardModalMobile></WebWizardModalMobile>
    </WebWizardProvider>
  </ThemeConfig>
)

export default WebWizardMobile
