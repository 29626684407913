import { Link } from "gatsby"
import React, { useContext } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"

const WebWizardModalMobile = () => {
  const stepTitles = [
    "Site you need",
    "Services you offer",
    "Design",
    "Packages",
    "Schedule a meeting",
  ]

  const {
    selectedStep,
    clearState,
    stepComponent,
    setStepComponent,
  } = useContext(WebWizardContext)

  const CurrentStepComponent = stepComponent.component

  return (
    <Container>
      <Link to="/">
        <ExitButton
          type="button"
          onClick={() => {
            clearState()
          }}
        >
          &times;
        </ExitButton>
      </Link>
      <ModalInner>
        <ContentSection
          maxWidth={selectedStep === 5 || selectedStep === 6 ? 100 : 70}
        >
          <CurrentStepComponent
            nextStep={setStepComponent}
            mobile={true}
          ></CurrentStepComponent>
        </ContentSection>
        <StepsSection
          step={selectedStep}
          display={selectedStep === 5 || selectedStep === 6 ? "none" : "flex"}
        >
          {stepTitles.map((value, index) => (
            <Step
              opacity={
                index < selectedStep ? 0 : index > selectedStep ? 0.7 : 1
              }
            >
              <StepCircle
                className={index === selectedStep ? "selected" : ""}
              ></StepCircle>
              <StepText className={index === selectedStep ? "selected" : ""}>
                <StepTitle>{`Step ${index + 1}`}</StepTitle>
                <StepDescription>{value}</StepDescription>
              </StepText>
            </Step>
          ))}
        </StepsSection>
      </ModalInner>
    </Container>
  )
}

export default WebWizardModalMobile

const Container = styled.div`
  z-index: 7;
  width: 100%;
  height: fit-content;
  position: relative;
`

const ModalInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: white;
`

const ExitButton = styled.button`
  position: fixed;
  color: ${({ theme }) => theme.black};
  background-color: white;
  border: 1px solid ${({ theme }) => theme.black};
  top: 15px;
  left: 15px;
  border-radius: 100%;
  min-width: unset;
  padding: 4px 0 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.brightRed};
    border-color: ${({ theme }) => theme.brightRed};
    transition: all 0.2s ease-out;
  }
  z-index: 4;
`

const ContentSection = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  order: 2;
  padding-top: 170px;
  padding-bottom: 50px;
`

const StepsSection = styled.div`
  width: fit-content;
  height: fit-content;
  display: ${({ display }) => display};
  flex-direction: row;
  justify-content: space-between;
  transition: margin-left ease-out 0.3s;
  margin-left: calc(50% - 245px - ${({ step }) => step} * 150px);
  position: fixed;
  background-color: white;
  padding: 20px 170px 20px 170px;
  z-index: 3;
  box-sizing: content-box;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 130px;
  width: 150px;
  justify-content: space-between;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.3s ease-out;
`

const StepCircle = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 100%;
  border: 1px solid #707070;
  margin-right: 47px;
  margin-left: 10px;
  transition: all ease-out 0.3s;

  &.selected {
    width: 50px;
    min-width: 50px;
    height: 50px;
    background-color: #167ffc;
    border: none;
    margin-left: 0;
    margin-right: 37px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    order: 2;
    margin-left: 0;
    margin-right: 0;
    margin-top: 17px;
    margin-bottom: 10px;

    &.selected {
      margin-left: 0;
      margin-right: 0;
      margin-top: 7px;
      margin-bottom: 0;
    }
  }
`

const StepText = styled.div`
  display: flex;
  flex-direction: column;

  &.selected {
    h2 {
      font: normal normal bold 28px/34px Metropolis;
    }

    h3 {
      font: normal normal bold 21px/25px Metropolis;
    }

    @media (max-width: ${({ theme }) => theme.tabletLarge}) {
      h2 {
        font: normal normal bold 21px/25px Metropolis;
      }

      h3 {
        font: normal normal bold 16px/19px Metropolis;
      }
    }
  }
`

const StepTitle = styled.h2`
  font: normal normal 300 28px/34px Metropolis;
  color: #1f2040;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font: normal normal 300 21px/25px Metropolis;
    text-align: center;
  }
`
const StepDescription = styled.h3`
  font: normal normal 300 21px/25px Metropolis;
  color: #1f2040;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font: normal normal normal 16px/19px Metropolis;
    text-align: center;
    margin-top: 10px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 100px;
  }
`
